import { Component, Prop, Vue } from 'vue-property-decorator';
import Timepicker from '@/components/ui/inputs/timepicker/timepicker.vue';
import { SpotScheduleDates, SpotScheduleDatesResponse } from '@/types/app/spot-schedule-dates.type';

@Component({
  name: 'SpotScheduleModal',
  components: {
    Timepicker
  }
})
export default class SpotScheduleModal extends Vue {
  begin = '00:00:00';
  end = '00:00:00';

  @Prop({
    required: true
  })
  dates!: SpotScheduleDates[];

  @Prop({
    required: true
  })
  updateCallback!: (value: SpotScheduleDatesResponse) => void;

  onApply() {
    if (this.begin && this.end) {
      this.updateCallback({
        begin: this.begin,
        end: this.end
      });
      this.cancel();
    }
  }

  cancel() {
    this.$emit('close');
  }

  get labels() {
    return this.dates.map(item => item.day).join(', ');
  }
}
