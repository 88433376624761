import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { generateRandomString } from '@/helpers/string.helper';
import Timepicker from '@/components/ui/inputs/timepicker/timepicker.vue';
import { SpotScheduleType } from '@/types/api/spot/spot-schedule.type';
import { Info } from 'luxon';
import SpotScheduleModal from '@/components/features/modals/spot-schedule-modal/spot-schedule-modal.vue';
import { SpotScheduleDates, SpotScheduleDatesResponse } from '@/types/app/spot-schedule-dates.type';
import FormBlock from '@/components/ui/form-block/form-block.vue';
import VisibleIcon from '@/components/ui/visible-icon/visibleIcon.vue';

@Component({
  components: {
    Timepicker,
    FormBlock,
    VisibleIcon,
  }
})
export default class SpotSchedule extends Vue {
  dateRange: SpotScheduleDates[] = [];
  allSelected = false;
  indeterminate = false;

  @Prop({
    required: false
  })
  schedule?: SpotScheduleType[];

  @Watch('dateRange')
  onSelectedChange(newValue) {
    const selected = newValue.filter(item => item.checked);
    if (selected.length === 0) {
      this.indeterminate = false;
      this.allSelected = false;
    } else if (selected.length === this.dateRange.length) {
      this.indeterminate = false;
      this.allSelected = true;
    } else {
      this.indeterminate = true;
      this.allSelected = false;
    }
  }

  created() {
    const days = Info.weekdays('long', { locale: 'en' });

    this.dateRange = days.map(item => {
      const date = this.schedule && this.schedule.find(sItem => sItem.day === item);
      return {
        id: generateRandomString(),
        day: item,
        timeFrom: date ? date.timeFrom : '00:00:00',
        timeTo: date ? date.timeTo : '00:00:00',
        checked: false
      }
    });
  }

  getDayLabelByIndex(index: number) {
    const daysLabel = Info.weekdays('short');
    return daysLabel[index];
  }

  onSelectTime() {
    this.$emit('update:schedule', this.dateRange.map(item => ({
      day: item.day,
      timeFrom: item.timeFrom,
      timeTo: item.timeTo
    })));
  }

  toggleAll(checked: boolean) {
    this.dateRange = this.dateRange.map(item => ({ ...item, checked }));
  }

  onCheckDate(event, index) {
    this.dateRange = this.dateRange.map((item, idx) => {
      return idx === index
        ? { ...item, checked: event.target.checked }
        : item
    });
  }

  onEdit() {
    this.$modal.show(SpotScheduleModal, {
      updateCallback: (result: SpotScheduleDatesResponse) => {
        this.dateRange = this.dateRange.map(item => item.checked ? {
          ...item,
          timeFrom: result.begin,
          timeTo: result.end,
        } : item);
        this.$emit('update:schedule', this.dateRange);
      },
      dates: this.selected
    }, {
      adaptive: true,
      height: 'auto',
      width: '300px'
    });
  }

  get selected() {
    return this.dateRange.filter(item => item.checked);
  }
}
