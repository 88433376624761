import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BMarkdownInput from '@/components/ui/inputs/b-markdown-input/bMarkdownInput.vue';
import GoogleMapPicker from '@/components/ui/inputs/google-map-picker/googleMapPicker.vue';
import BFileInputValidation from '@/components/ui/inputs/b-file-input-validation/bFileInputValidation.vue';
import { SpotCreateRequest, SpotUpdateLocaleRequest, SpotUpdateRequest } from '@/types/request/spot-request.types';
import { SpotType } from '@/types/api/spot/spot.type';
import { Action, Getter } from 'vuex-class';
import { LanguageType } from '@/types/api/info/language.type';
import { SpotLocaleType } from '@/types/api/spot/spot-locale.type';
import { LocaleUpdateEvent } from '@/components/ui/locale-updater/localeUpdater';
import ImageSlider from '@/components/ui/image-slider/imageSlider.vue';
import LocaleUpdater from '@/components/ui/locale-updater/localeUpdater.vue';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';
import { ImageType } from '@/types/api/image.type';
import { SpotCard } from '@/store/modules/spot/actions';
import { GeopointType } from '@/types/app/geopoint.type';
import VisibleIcon from '@/components/ui/visible-icon/visibleIcon.vue';
import SpotScheduleDates from '@/components/ui/spot-schedule/spot-schedule.vue';
import { SpotScheduleType } from '@/types/api/spot/spot-schedule.type';
import FormBlock from '@/components/ui/form-block/form-block.vue';
import BRadioInputValidation from '@/components/ui/inputs/b-radio-input-validation/bRadioInputValidation.vue';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { VenuesLinkStayRequest, VenuesRequest } from '@/types/request/venue-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { VenueType } from '@/types/api/venue/venue.type';
import { PointType } from '@/types/api/point.type';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, GridOptions, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { StayRequest } from '@/types/request/stay-request.types';
import { StayType } from '@/types/api/stay/stay.type';
import { AgGridVue } from 'ag-grid-vue';
import AddStay from '@/components/ui/modals/addStay.vue';
import { debounceFilter } from '@/helpers/filter.helper';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import MultiselectInput from '@/components/ui/inputs/multiselect-input/multiselect-input.vue';
import { APP_OF, getAppTypeLabel, getAppTypeList } from '@/constants/app.constants';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';

const HOTEL_MODE_MANUAL = 1;
const HOTEL_MODE_AUTO = 2;

interface IGeoAddress {
  spotAddress: PointType | null;
  eventAddress: PointType | null;
}

const getTitle = () => window.location.href.includes('/spot/update') ? 'spot.updatePlace' : 'spot.addPlace';

@Component({
  name: 'SpotForm',
  page: {
    title: getTitle()
  },
  components: {
    ValidationObserver,
    BTextInputValidation,
    BMarkdownInput,
    GoogleMapPicker,
    BFileInputValidation,
    ImageSlider,
    LocaleUpdater,
    BSelectInputValidation,
    VisibleIcon,
    SpotScheduleDates,
    FormBlock,
    BRadioInputValidation,
    AgGridVue,
    PageTitle,
    MultiselectInput,
    DatepickerSingle,
  },
})
export default class SpotForm extends Vue {
  title = getTitle();
  tryingToUpdate = false;
  tryingToUpdateImages = false;
  tryingToGetVenue = true;
  image = null;
  name = '';
  description = '';
  address = '';
  latitude = '';
  longitude = '';
  images: ImageType[] = [];
  initialPointLocation: null | [number, number] = null;
  language = '';
  locales: SpotLocaleType[] = [];
  isBlocked = false;
  isOnline = false;
  isVIP = false;
  isJunketAllow = false;
  featured = false;
  featuredStartAt = '';
  featuredEndAt = '';
  schedule: SpotScheduleType[] | null = null;
  hotelMode = HOTEL_MODE_MANUAL;
  agGridStays: AgGridHelper | null = null;
  agGridStaysNear: AgGridHelper | null = null;
  filterName = '';
  filter: any = {
    name: null,
    lat: null,
    long: null,
    radius: null,
    size: '640x400'
  };
  filterStaysNear: any = {
    lat: null,
    long: null,
    radius: 10000, // 10km (in meters)
    size: '640x400',
    ...(this.filterName ? { name: this.filterName } : {})
  };
  venue: VenueType | null = null;
  pointLocation: null | [number, number] = null;
  stays: StayType[] = [];
  appMeta: any[] = [{ value: APP_OF, text: getAppTypeLabel(APP_OF) }];

  @Prop({ required: false })
  spot?: SpotType;

  @Prop({ required: true })
  updateCallback!: (params: SpotCreateRequest | SpotUpdateRequest) => Promise<SpotType>;

  @Action('createSpotLocale', { namespace: 'spot' })
  createSpotLocale!: (data: SpotCard<SpotUpdateLocaleRequest>) => Promise<SpotLocaleType>;

  @Action('updateSpotLocale', { namespace: 'spot' })
  updateSpotLocale!: (data: SpotCard<SpotUpdateLocaleRequest>) => Promise<SpotLocaleType>;

  @Action('updateImages', { namespace: 'spot' })
  updateImages!: (data: SpotCard<File[]>) => Promise<SpotType>;

  @Action('deleteImage', { namespace: 'spot' })
  deleteImage!: (data: SpotCard<string>) => Promise<SpotType>;

  @Getter('getLanguages', { namespace: 'info' })
  languages?: LanguageType[];

  @Action('getVenues', { namespace: 'venues' })
  getVenues!: (params: VenuesRequest) => Promise<ListResponseType<VenueType>>;

  @Action('getStays', { namespace: 'stay' })
  getStays!: (params: StayRequest) => Promise<ListResponseType<StayType>>;

  @Action('unlinkStay', { namespace: 'venues' })
  unlinkStay!: (params: VenuesLinkStayRequest) => Promise<VenueType>;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGridStays && newValue !== oldValue) {
      this.agGridStays.getGridApi()!.refreshHeader();
    }

    if (this.agGridStaysNear && newValue !== oldValue) {
      this.agGridStaysNear.getGridApi()!.refreshHeader();
    }
  }

  created(): void {
    if (this.spot) {
      this.images = this.spot.images;
      this.name = this.spot.name;
      this.description = this.spot.description;
      this.address = this.spot.address;
      this.initialPointLocation = this.spot.geopoint ? this.spot.geopoint.coordinates : null;
      this.locales = [...this.spot.locales];
      this.isBlocked = this.spot.isBlocked;
      this.isOnline = this.spot.isOnline;
      this.isVIP = this.spot.isVIP;
      this.isJunketAllow = this.spot.isJunketAllow;
      this.featured = this.spot.featured;
      this.featuredStartAt = this.spot.featuredStartAt;
      this.featuredEndAt = this.spot.featuredEndAt;
      this.schedule = this.spot.scheduleSpot;
      this.appMeta = this.spot.appMeta.map(item => ({ value: item, text: getAppTypeLabel(item) }));

      this.getVenues({
        spotId: this.spot.id,
        take: 1
      })
        .then(response => {
          const item = response.items[0];
          if (item) {
            this.venue = item;
            this.stays = item.stays || [];
            const getAddress = this.geoAddress(item);
            const geo = getAddress.spotAddress || getAddress.eventAddress;

            if (geo) {
              this.filter.long = geo.coordinates[0];
              this.filter.lat = geo.coordinates[1];
              this.filterStaysNear.long = geo.coordinates[0];
              this.filterStaysNear.lat = geo.coordinates[1];
              this.pointLocation = geo.coordinates;
              this.tryingToGetVenue = false;
            }
          }
        })
        .catch(() => this.tryingToGetVenue = false);
      this.debounceApplyFilter = debounceFilter(this.debounceApplyFilter);
    }
  }

  beforeMount() {
    this.agGridStays = new AgGridHelper(this.initGridStaysOptions())
      .enableAutoSizeColumns(false)
      .build();

    this.agGridStaysNear = new AgGridHelper(this.initGridStaysNearOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridStaysNearChangeCallback)
      .build();
  }

  gridStaysNearChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getStays({
      take: general.limit,
      skip: general.offset,
      ...this.filterStaysNear,
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridStaysOptions(): GridOptions {
    return {
      columnDefs: this.initStaysColumnDef(),
      enableSorting: false,
      rowHeight: 100,
    }
  }

  initGridStaysNearOptions(): GridOptions {
    return {
      columnDefs: this.staysColumnDef,
      enableSorting: false,
      rowHeight: 100,
      suppressHorizontalScroll: true,
    }
  }

  get staysColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('spot.nameAddress'),
        field: 'name',
        cellRenderer: 'nameAddressCellRenderer',
        cellRendererParams: {
          imageUrl: (params: ICellRendererParams) => params.data && params.data.images[0],
          text: (params: ICellRendererParams) => params.data && params.data.hotelName,
          address: (params: ICellRendererParams) => params.data && params.data.hotelAddress,
        }
      },
      {
        headerValueGetter: () => this.$t('stay.distance'),
        field: 'distance',
      },
      {
        headerValueGetter: () => this.$t('stay.price'),
        field: 'price',
        valueFormatter: (params: ValueFormatterParams) => params.data && params.data.price.toFixed(2)
      },
    ];
  }

  initStaysColumnDef(): ColDef[] {
    return [
      ...this.staysColumnDef,
      {
        headerName: '',
        field: 'actions',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        cellRenderer: 'actionsCellRenderer',
        cellRendererParams: {
          buttons: [
            {
              type: 'delete',
              actionCallback: this.onDeleteStay,
              btnContent: '<i class="fe-trash"></i>',
            },
          ]
        }
      },
    ];
  }

  onDeleteStay(params: ICellRendererParams) {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.unlinkStay({
              stayId: params.data.stayId,
              venueId: this.venue!.id
            })
              .then(response => {
                this.stays = response.stays || [];
              })
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }

  onChooseLocation(value: GeopointType) {
    this.latitude = value ? value.lat : '';
    this.longitude = value ? value.lng : '';
    this.address = value ? value.address : '';
  }

  onImageUpload(event) {
    if (this.spot) {
      this.onAddImages(event);
    } else {
      this.image = event;
      this.images = [];
    }
  }

  onApply() {
    if (!this.tryingToUpdate && this.latitude && this.longitude) {
      this.tryingToUpdate = true;
      this.updateCallback({
        address: this.address,
        latitude: this.latitude,
        longitude: this.longitude,
        isOnline: this.isOnline,
        isVIP: this.isVIP,
        isJunketAllow: this.isJunketAllow,
        featured: this.featured,
        appMeta: this.appMeta.length ? this.appMeta.map(item => item.value) : [APP_OF],
        ...(!this.spot ? { name: this.name } : {}),
        ...(!this.spot ? { description: this.description } : {}),
        ...(!this.spot ? { language: this.language } : {}),
        ...(!this.spot ? { image: this.image as any } : {}),
        ...(this.spot ? { isBlocked: this.isBlocked as any } : {}),
        ...(this.schedule ? { schedule: this.schedule } : {}),
        ...(this.featuredStartAt ? { featuredStartAt: this.featuredStartAt } : {}),
        ...(this.featuredEndAt ? { featuredEndAt: this.featuredEndAt } : {}),
      })
        .then(() => {
          this.tryingToUpdate = false;
        })
        .catch(() => {
          this.tryingToUpdate = false;
        });
    }
  }

  get languageOptions() {
    return [
      { value: '', text: '---' },
      ...(this.languages || []).map(item => ({ value: item.code, text: item.code.toUpperCase() }))
    ];
  }

  onUpdateLocale(event: LocaleUpdateEvent) {
    const promise = event.locale
      ? this.updateSpotLocale({ id: event.locale.id, params: event.editedLocale as any })
      : this.createSpotLocale({ id: this.spot!.id, params: event.editedLocale as any });

    promise.then(result => {
      this.locales = this.locales.filter(item => item.languageId !== event.language.id);
      this.locales.push(result);
    });
  }

  onDeleteImage(image: ImageType) {
    if (!this.tryingToUpdateImages) {
      this.tryingToUpdateImages = true;
      return this.deleteImage({ id: this.spot!.id, params: image.id })
        .then(result => {
          this.images = result.images;
          this.tryingToUpdateImages = false;
        })
        .catch(() => this.tryingToUpdateImages = false);
    }
  }

  onAddImages(images: File[]) {
    if (!this.tryingToUpdateImages) {
      this.tryingToUpdateImages = true;
      return this.updateImages({ id: this.spot!.id, params: images })
        .then(result => {
          this.images = result.images;
          this.tryingToUpdateImages = false;
        })
        .catch(() => this.tryingToUpdateImages = false);
    }
  }

  onChangeSchedule(value) {
    this.schedule = value;
  }

  get hotelModeOptions() {
    return [
      { value: HOTEL_MODE_MANUAL, text: this.$t('spot.hotelModeManual') },
      { value: HOTEL_MODE_AUTO, text: this.$t('spot.hotelModeAuto') },
    ]
  }

  geoAddress(venue: VenueType): IGeoAddress {
    const spotAddress = venue!.spot ? venue!.spot.geopoint : null;
    const eventAddress = venue!.events && venue!.events.length == 1 ? venue!.events[0].geopoint : null;

    return { spotAddress, eventAddress };
  }

  get isHotelModeManual() {
    return this.hotelMode === HOTEL_MODE_MANUAL;
  }

  onChangeHotelMode(value: number) {
    if (value === HOTEL_MODE_AUTO) {
      this.agGridStaysNear?.getGridApi()!.sizeColumnsToFit();
    } else {
      this.agGridStays?.getGridApi()!.sizeColumnsToFit();
    }
  }

  onAddStay() {
    this.$modal.show(AddStay, {
      updateCallback: (result: StayType[]) => {
        this.stays = result || [];
      },
      venue: this.venue,
      coordinates: this.pointLocation,
    }, {
      adaptive: true,
      height: 'auto',
      width: '80%',
    });
  }

  debounceApplyFilter() {
    if (!this.isHotelModeManual) {
      if (this.filterName) {
        this.filterStaysNear = {
          ...this.filterStaysNear,
          name: this.filterName
        }
      } else {
        delete this.filterStaysNear.name;
      }

      this.applyFilter();
    }
  }

  applyFilter() {
    this.agGridStaysNear?.refreshInfinity();
  }

  get filteredStays() {
    return this.stays.filter(item => item.hotelName.toLowerCase().includes(this.filterName.toLowerCase()));
  }

  get appTypeOptions() {
    return getAppTypeList();
  }
}
