import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { VenueType } from '@/types/api/venue/venue.type';
import { VenuesLinkStayRequest } from '@/types/request/venue-request.types';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { StayRequest } from '@/types/request/stay-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { StayType } from '@/types/api/stay/stay.type';
import debounce from 'lodash/debounce';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, GridOptions, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { AgGridVue } from 'ag-grid-vue';
import GoogleMapPicker from '@/components/ui/inputs/google-map-picker/googleMapPicker.vue';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import { GeopointType } from '@/types/app/geopoint.type';

@Component({
  name: 'AddStayModal',
  components: {
    AgGridVue,
    GoogleMapPicker,
    BTextInputValidation,
  },
})
export default class AddStayModal extends Vue {
  pointLocation: null | [number, number] = null;
  agGrid: AgGridHelper | null = null;
  filter: any = {
    name: null,
    lat: null,
    long: null,
    radius: null,
    size: '640x400'
  };
  tryingToFindStays = false;

  @Prop({
    required: true
  })
  venue!: VenueType;

  @Prop({
    required: false
  })
  coordinates?: [number, number];

  @Action('getStays', { namespace: 'stay' })
  getStays!: (params: StayRequest) => Promise<ListResponseType<StayType>>;

  @Action('linkStay', { namespace: 'venues' })
  linkStay!: (params: VenuesLinkStayRequest) => Promise<VenueType>;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGrid && newValue !== oldValue) {
      this.agGrid.getGridApi()!.refreshHeader();
    }
  }

  created(): void {
    this.debounceLatLongInput = debounce(this.debounceLatLongInput, 500);

    if (this.coordinates) {
      this.filter.long = this.coordinates[0];
      this.filter.lat = this.coordinates[1];
      this.pointLocation = this.coordinates;
    }
  }

  beforeMount() {
    this.agGrid = new AgGridHelper(this.initGridOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridChangeCallback)
      .build();
  }

  gridChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getStays({
      take: general.limit,
      skip: general.offset,
      ...this.filter,
      radius: this.filter.radius || null
    })
      .then(response => {
        additional.api.hideOverlay();
        this.tryingToFindStays = false;
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      })
      .catch(response => {
        this.tryingToFindStays = false;
        return response;
      });
  }

  initGridOptions(): GridOptions {
    return {
      columnDefs: this.initColumnDef(),
      enableSorting: false,
      rowHeight: 100,
    }
  }

  initColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('spot.nameAddress'),
        field: 'name',
        cellRenderer: 'nameAddressCellRenderer',
        cellRendererParams: {
          imageUrl: (params: ICellRendererParams) => params.data && params.data.images[0],
          text: (params: ICellRendererParams) => params.data && params.data.hotelName,
          address: (params: ICellRendererParams) => params.data && params.data.hotelAddress,
        }
      },
      {
        headerValueGetter: () => this.$t('stay.distance'),
        field: 'distance',
      },
      {
        headerValueGetter: () => this.$t('stay.price'),
        field: 'price',
        valueFormatter: (params: ValueFormatterParams) => params.data && params.data.price.toFixed(2)
      },
      {
        headerName: '',
        field: 'actions',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        cellRenderer: 'actionsCellRenderer',
        cellRendererParams: {
          buttons: [
            {
              type: 'link',
              actionCallback: this.onLinkStay,
              btnContent: '<i class="fe-link"></i>',
            },
          ]
        }
      },
    ];
  }

  onChooseLocation(value: GeopointType) {
    this.filter.lat = value ? value.lat : '';
    this.filter.long = value ? value.lng : '';
  }

  goToVenues() {
    this.$router.push({ name: 'venues' });
  }

  onFindStays() {
    if (!this.tryingToFindStays) {
      this.tryingToFindStays = true;
      if (this.agGrid) {
        this.agGrid.refreshInfinity();
      }
    }
  }

  debounceLatLongInput() {
    if (this.filter.lat && this.filter.long) {
      this.pointLocation = [this.filter.long, this.filter.lat];
    }
  }

  onLinkStay(params: ICellRendererParams) {
    this.linkStay({
      stayId: params.data.stayId,
      venueId: this.venue!.id
    })
      .then(response => {
        this.$emit('link', response.stays || [])
      })
  }
}
