import { render, staticRenderFns } from "./spotForm.vue?vue&type=template&id=705c7236&scoped=true&"
import script from "./spotForm.ts?vue&type=script&lang=ts&"
export * from "./spotForm.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705c7236",
  null
  
)

export default component.exports