import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import { Action } from 'vuex-class';
import { SpotCreateRequest } from '@/types/request/spot-request.types';
import { SpotType } from '@/types/api/spot/spot.type';
import SpotForm from '@/components/pages/spots/spot-form/spotForm.vue';
import { VenuesCreateRequest, VenuesLinkSpotRequest } from '@/types/request/venue-request.types';
import { VenueType } from '@/types/api/venue/venue.type';

@Component({
  components: {
    Layout,
    SpotForm
  },
})
export default class SpotCreate extends Vue {
  @Action('createSpot', { namespace: 'spot' })
  createSpot!: (params: SpotCreateRequest) => Promise<SpotType>;

  @Action('createVenue', { namespace: 'venues' })
  createVenue!: (params: VenuesCreateRequest) => Promise<VenueType>;

  @Action('linkSpot', { namespace: 'venues' })
  linkSpot!: (params: VenuesLinkSpotRequest) => Promise<VenueType>;

  onApply(params: SpotCreateRequest) {
    return this.createSpot(params)
      .then(spotResponse => {
        return this.createVenue({ name: `${params.name} Group` })
          .then(venueResponse => {
            return this.linkSpot({ venueId: venueResponse.id, spotId: spotResponse.id })
              .then(() => this.$router.push({ name: 'spots' }))
          })
      });
  }
}
